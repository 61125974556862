.abouts {
  display: flex;
  padding-bottom: 70px;
  padding-top: 60px; }

.abouts_layer {
  padding-left: 147px; }

.layer_img {
  display: block;
  width: 430px;
  height: 430px; }

.layer_img img {
  position: absolute; }

.abouts_banner {
  width: 563px;
  margin-left: 153px; }

.banner_title {
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  padding-top: 35px;
  text-transform: uppercase; }

.banner_description {
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  padding-top: 60px;
  padding-bottom: 36px; }

.abouts_link {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  float: right;
  color: #3A3D92; }

.abouts_link img {
  padding-left: 5px; }

@media (max-width: 1300px) {
  .abouts_layer {
    padding-left: 10px; } }

@media (max-width: 992px) {
  .abouts {
    display: flex;
    flex-direction: column;
    padding-top: 20px; }
  .abouts_layer {
    padding: 20px; }
  .abouts_banner {
    margin-left: 20px; } }

@media (max-width: 430px) {
  .layer_img {
    width: 100%;
    height: 350px; }
  .layer_img img {
    width: 90%; }
  .abouts_banner {
    width: auto; }
  .banner_title {
    padding-top: 0px; }
  .banner_description {
    padding-top: 0;
    font-size: 14px; } }
