.swiper-pagination-fraction {
  font-weight: 700;
  color: #3A3D92; }

.swiper-wrapper {
  justify-content: space-between; }

.swiper-pagination {
  display: flex;
  justify-content: flex-start; }

.pagination_wrapper {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 16px 20px;
  width: fit-content;
  position: absolute;
  bottom: -10px; }

.swiper-button-next {
  position: absolute;
  right: 0;
  width: 56px;
  height: 56px;
  background: rgba(255, 255, 255, 0.2); }
  .swiper-button-next:after {
    font-size: 18px;
    color: #737988; }

.swiper-button-prev {
  position: absolute;
  left: 0;
  width: 56px;
  height: 56px;
  background: rgba(255, 255, 255, 0.2); }
  .swiper-button-prev:after {
    font-size: 18px;
    color: #737988; }

@media (max-width: 430px) {
  .swiper-pagination {
    position: static; }
  .top_slider_box .swiper-pagination {
    position: absolute;
    justify-content: flex-end; }
  .pagination_wrapper {
    padding: 20px 50px; } }

@media (max-width: 380px) {
  .pagination_wrapper {
    padding: 20px; } }
