.footer {
  z-index: 2; }

.footer_top {
  display: flex;
  width: 100%;
  height: auto;
  position: relative; }

.footer_contacts {
  display: block;
  z-index: 1;
  width: 50%;
  background-color: black;
  padding-bottom: 50px; }

.footer_contacts-title {
  color: #ffffff;
  height: 37px;
  padding-left: 119px;
  padding-top: 100px;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  text-transform: uppercase; }

.footer_contacts-address {
  display: flex;
  padding-left: 119px;
  margin-top: 46px;
  width: 360px;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff; }

.footer_contacts-phone {
  display: flex;
  padding-left: 119px;
  padding-top: 46px;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff; }

.footer_contacts-phone a {
  color: #ffffff; }

i {
  margin-right: 15px; }

.footer_form {
  position: absolute;
  display: block;
  z-index: 3;
  width: 346px;
  background-color: #ffffff;
  transform: translate(-150px);
  bottom: 0; }

.footer_form-title {
  padding-top: 40px;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px; }

.form_button {
  border: 1px solid #3a3d92;
  background-color: #ffffff;
  color: #3a3d92;
  width: 189px;
  height: 51px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  cursor: pointer; }
  .form_button_wrapper {
    margin-top: 30px; }

.btn_arrow {
  padding-left: 10px; }

.footer_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px; }

.footer_nav ul {
  display: flex;
  width: 680px;
  margin: 0; }

.footer_nav a {
  text-transform: uppercase; }

.footer_nav ul li {
  padding-right: 30px; }

.db_logo {
  padding-left: 60px; }

.sw_logo {
  padding-right: 60px; }

ul li a {
  color: black; }

.footer_inputs {
  padding: 30px; }
  .footer_inputs__item input {
    width: 100%;
    width: -moz-available;
    /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    padding: 10px;
    margin: 2px;
    border: 1px solid #cfcfcf; }

.footer_map {
  width: 50%; }
  @media (max-width: 430px) {
    .footer_map {
      width: 100%; } }
  .footer_map iframe {
    width: 100%; }

.address_title {
  white-space: nowrap; }

@media (max-width: 430px) {
  .footer_top {
    flex-direction: column-reverse; }
  .footer_contacts {
    width: auto; }
  .footer_contacts-title {
    padding-left: 50px;
    padding-top: 30px;
    font-size: 24px; }
  .footer_contacts-address,
  .footer_contacts-phone {
    padding-left: 40px;
    padding-top: 20px;
    font-size: 15px;
    display: flex;
    align-items: baseline;
    width: auto; }
  .footer_form {
    position: static;
    transform: translate(0);
    margin-top: 0; }
  iframe {
    width: 100%;
    height: 300px; }
  .footer_nav {
    display: none; }
  .footer_form {
    width: 100%; }
  .footer_bottom {
    justify-content: space-around; }
  .db_logo {
    padding-left: 0px; }
  .sw_logo {
    padding-right: 0px; } }
