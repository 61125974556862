.services {
  margin-top: 60px; }

.services_items {
  display: flex;
  justify-content: space-between; }

.services_item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 110px;
  background-color: #ffffff;
  box-shadow: 0px 0px 50px rgba(0, 0, 24, 0.13); }

.services_description {
  font-weight: 400;
  font-size: 18px;
  padding-left: 30px; }

@media (max-width: 430px) {
  .services {
    margin-top: 0px; } }
