@charset "UTF-8";
.top_slider {
  display: flex;
  height: 477px;
  position: relative; }
  .top_slider_box {
    width: 50%; }

.topSlider {
  padding-top: 20px; }

.top_controls {
  display: flex;
  transform: translateY(-50px);
  width: 120px;
  padding: 20px;
  background-color: #ffffff;
  opacity: 0.5;
  z-index: 9; }

.top_prev {
  display: block;
  z-index: 6; }

.top_vector {
  transform: translateY(-5px);
  padding: 0 10px; }

.top_logo {
  display: block;
  width: 378px;
  height: 458px;
  padding-top: 50px; }

.top_logo img {
  position: absolute; }

.top_menu {
  display: flex;
  flex-direction: column;
  width: 390px;
  margin-left: 83px;
  padding-top: 82px; }

.top_menu-cover {
  display: block;
  width: 400px;
  background-color: #3a3d92; }

.top_menu-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.25em;
  color: #ffffff;
  padding-left: 62px;
  padding-top: 27px;
  text-transform: uppercase; }
  .top_menu-title_2 {
    margin-top: -27px;
    padding-top: 0; }

.top_menu-subtitle {
  font-weight: 700;
  font-size: 70px;
  line-height: 26px;
  color: #ffffff;
  padding-left: 50px; }

.top_menu-descriptions {
  padding-top: 52px;
  padding-left: 61px; }
  .top_menu-descriptions div {
    width: 247px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px; }

.top_link {
  display: flex;
  margin-top: 18px;
  margin-left: 60px;
  font-weight: 700;
  width: 150px;
  padding: 15px 20px;
  padding-right: 150px;
  color: #3a3d92;
  border: #3a3d92 solid 1px;
  z-index: 11;
  position: relative; }
  .top_link::after {
    content: "→";
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 25px; }

.top_link img {
  height: 10px;
  margin-top: 5px;
  padding-left: 10px; }

.description_logo {
  padding-left: 270px;
  z-index: 10;
  transform: translateY(-120px);
  width: min-content; }

.menu_cover-after {
  transform: translateY(-472px);
  z-index: 2;
  padding-left: 390px; }

.top_slide img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

@media (max-width: 1300px) {
  .top_logo {
    display: none; }
  .top_menu {
    margin-left: 0; }
  .topContacts a {
    display: none; }
  .topContacts a.icotel {
    display: block; }
  .top_logo {
    display: none; }
  .top_menu {
    width: 50%; } }

@media (max-width: 800px) {
  .top_slider_box {
    width: 100%; }
  .top_menu {
    position: absolute;
    z-index: 10; } }

@media (max-width: 430px) {
  .top_slider {
    flex-direction: column-reverse; }
    .top_slider .top_link {
      margin-left: 20px;
      color: #ffffff;
      border: #ffffff solid 1px; }
  .top_controls {
    margin-left: 235px; }
  .top_menu {
    height: 100px;
    padding: 0;
    width: 100%;
    position: static;
    margin-top: 60px;
    background-color: #3a3d92; }
  .top_slider-box {
    height: 300px; }
  .top_menu-title {
    font-size: 11.0667px;
    line-height: 13px;
    padding-left: 30px; }
    .top_menu-title_2 {
      margin-top: -15px;
      margin-left: 20px;
      padding-top: 0; }
  .top_menu-subtitle {
    font-size: 22.1333px;
    line-height: 27px; }
  .top_menu-cover {
    width: 220px;
    margin-top: -142px;
    z-index: 5; }
    .top_menu-cover::before {
      content: "";
      position: absolute;
      width: 35.41px;
      height: 83px;
      left: 215.8px;
      background: rgba(58, 61, 146, 0.5); }
  .top_menu-descriptions {
    padding: 10px 0 5px 20px;
    background-color: #3a3d92;
    color: #ffffff; }
  .description_logo {
    padding-left: 0;
    transform: translate(250px, -100px);
    width: 105px;
    height: 105px; }
    .description_logo img {
      width: 100%;
      height: 100%; }
  .menu_cover-after {
    display: none; } }

@media (max-width: 380px) {
  .description_logo {
    width: 70px;
    height: 70px;
    transform: translate(230px, -70px); } }
