.transport_right-title {
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 37px;
  padding-top: 30px;
  padding-bottom: 10px;
  text-transform: uppercase; }

.transports {
  display: flex;
  justify-content: space-between; }

.transports_left {
  padding-left: 20px;
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  width: 50%; }

.transports_item {
  position: relative;
  padding: 10px;
  width: 50%;
  height: auto; }
  .transports_item .img_bg {
    position: relative;
    height: 60%; }
    .transports_item .img_bg__1 {
      width: 100%;
      height: 100%; }
    .transports_item .img_bg::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.6);
      z-index: 1; }
  .transports_item:hover .img_bg::after {
    background: rgba(0, 0, 0, 0);
    z-index: -1; }

.transports_item_a {
  position: absolute;
  display: none;
  padding: 15px 30px;
  background-color: #3A3D92;
  color: #ffffff;
  transition: 5ms;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.transports_item:hover .transports_item_a {
  transition: 5ms;
  display: block; }

.transports_item p {
  margin: 0;
  font-weight: 400;
  font-size: 15px; }

.transports_item__title {
  background-color: #ffffff;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  padding: 34px 0;
  height: 40%; }
  .transports_item__title div {
    font-size: 18px;
    font-weight: normal; }

.transports_right {
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  width: 50%; }

.transport_item {
  background-color: #3A3D92;
  text-align: center; }

.transport_item__title {
  color: #ffffff;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  height: 40%; }

.transport_item__description {
  color: #ffffff;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  padding: 5px 5%;
  text-align: left; }

.transport_item img {
  transform: translateY(38px);
  width: 100%; }

.transport_item_titles {
  padding: 50px 0; }

.beton_bottom {
  display: flex;
  justify-content: space-between;
  padding: 0 20px; }

@media (max-width: 1435px) {
  .transports {
    flex-direction: column-reverse; }
  .transport_item__description {
    display: flex;
    justify-content: center; }
  .transport_item img {
    transform: translateY(0);
    width: auto; }
  .transports_left, .transports_right {
    width: 100%;
    padding: 20px; }
  .transports_right {
    padding-right: 0px; }
  .transports_item {
    width: 25%; } }

@media (max-width: 1200px) {
  .transports_item {
    width: 50%; } }

@media (max-width: 992px) {
  .transports {
    display: flex;
    flex-direction: column-reverse; }
  .transport_item img {
    width: 100%;
    margin-left: 0; } }

@media (max-width: 430px) {
  .transports_right {
    padding: 0; }
  .transports_left {
    padding: 0;
    margin-top: 20px; }
  .transports_item {
    width: 50%;
    padding: 0;
    margin-bottom: 20px; }
  .transports_item img {
    padding-left: 10px;
    object-fit: cover; }
  .transports_item__title {
    font-size: 12px;
    line-height: 15px;
    padding: 10px 0; }
    .transports_item__title div {
      font-size: 12px;
      margin-bottom: 20px; }
  .transport_right-title {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 24px; }
  .transport_item__title {
    font-size: 14px;
    line-height: 17px; }
  .transport_item__description {
    font-size: 14px;
    line-height: 17px; }
  .transports_item_a {
    width: 100px;
    padding: 10px; }
  .beton_bottom {
    padding: 0; } }

.stock__btn {
  padding: 20px;
  display: flex;
  justify-content: center; }
  .stock__btn .top_link {
    margin: 0px; }
