*,
*::before,
*::after {
  box-sizing: border-box; }

.storage_container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px; }

.tabs {
  display: flex;
  flex-direction: column;
  padding-left: 15px; }

.tabs_links-title {
  text-align: left;
  font-weight: 700;
  padding-bottom: 70px;
  font-size: 30px;
  line-height: 43px; }

.tabs_links-title p {
  margin: 0; }

.tabs__links a {
  display: inline-block;
  text-decoration: none;
  text-align: left;
  color: black;
  padding: 25px 10px; }

.tabs__links a:hover {
  background-color: rgba(227, 242, 253, 0.3); }

#content-1:target ~ .tabs__links a[href="#content-1"],
#content-2:target ~ .tabs__links a[href="#content-2"],
#content-3:target ~ .tabs__links a[href="#content-3"],
#content-4:target ~ .tabs__links a[href="#content-4"] {
  background-color: rgba(255, 255, 255, 0.5);
  border-left: 2px solid blue;
  font-weight: 800;
  color: #2f2483;
  cursor: default; }

#content-1:target ~ a[href="#content-1"],
#content-2:target ~ a[href="#content-2"],
#content-3:target ~ a[href="#content-3"],
#content-4:target ~ a[href="#content-4"] {
  background-color: rgba(255, 255, 255, 0.5);
  border-left: 2px solid blue;
  font-weight: 800;
  color: #2f2483;
  cursor: default; }

.tabs > div:not(.tabs__links) {
  display: none;
  order: 1;
  flex-grow: 1; }

.tabs > div:target {
  display: block; }

.storage_item_wrapper {
  display: flex;
  flex-direction: column; }

.storage_item_wrapper > div:not(.tabs__links) {
  display: none;
  order: 1;
  flex-grow: 1; }

.storage_item_wrapper > div:target {
  display: block; }

.storage_items-top {
  display: flex;
  justify-content: space-around;
  padding-bottom: 20px; }

.storage_items-midle {
  display: flex;
  justify-content: space-around; }

.storage_item {
  display: flex;
  width: 48%;
  background-color: #ffffff;
  font-weight: 700; }
  .storage_item img {
    width: 50%;
    height: 100%; }

.storage_item p {
  font-weight: 400;
  color: #828282; }

.storage_item-title {
  width: 50%;
  padding-top: 25px;
  padding-left: 17px; }

.storage_bottom-item {
  margin-top: 20px;
  margin-left: 10px;
  position: relative;
  width: 913px;
  height: 184px; }

.storage_item-blue {
  position: absolute;
  padding: 14px; }

.storage_item-logo {
  position: absolute;
  padding: 30px 66px;
  z-index: 2; }

.storage_item-white {
  padding: 14px 170px; }

.storage_item-white p {
  width: 163px;
  margin-top: -110px;
  padding-left: 37px;
  font-weight: 700; }

.storage_btn {
  position: absolute;
  color: #ffffff;
  background-color: #3A3D92;
  padding: 16px 32px;
  transform: translateY(-50px);
  margin-left: 42%;
  font-weight: 700;
  font-size: 15px; }

.storage_btn img {
  padding-left: 10px; }

@media (min-width: 476px) {
  .tabs {
    flex-direction: row; }
  .tabs__links {
    flex-direction: column;
    border: none;
    box-shadow: none; }
  .tabs__links a {
    border: 1px solid #e3f2fd;
    box-shadow: 0 2px 4px 0 #e3f2fd;
    margin-bottom: 8px; }
  .tabs__links a:last-child {
    margin-bottom: 0; }
  .tabs > div:not(.tabs__links) {
    padding-left: 15px; } }

@media (max-width: 430px) {
  .tabs {
    flex-direction: column !important;
    padding-left: 0; }
  .tabs__links {
    flex-direction: column;
    width: auto;
    margin-bottom: 0;
    white-space: normal; }
  .tabs_links-title {
    padding-bottom: 15px;
    padding-left: 15px; }
  .storage_items-top,
  .storage_items-midle {
    flex-direction: column;
    padding-bottom: 0; }
  .storage_items-bottom {
    width: 100%; }
  .storage_item {
    width: 100%;
    margin-bottom: 10px; }
  .storage_item img {
    width: 50%; }
  .storage_bottom-item {
    width: 50%;
    margin-left: 0; }
  .storage_btn {
    margin-left: 0;
    transform: translate(80px, 40px); }
  .storage_container {
    padding: 0; } }
