.blog {
  display: flex;
  overflow: hidden;
  padding: 60px 0px 67px 0px; }
  @media (max-width: 1000px) {
    .blog {
      flex-direction: column; } }

.blog_left {
  width: 260px;
  position: relative; }
  @media (max-width: 1000px) {
    .blog_left {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse; } }

.blog_link {
  display: inline-block;
  padding-left: 100px;
  padding-top: 30px;
  color: #3A3D92;
  font-weight: 700;
  line-height: 19px;
  font-size: 15px;
  white-space: nowrap; }

.blog_link img {
  padding-left: 10px; }

.blog_title {
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  width: 220px;
  height: 74px;
  padding-top: 130px;
  padding-left: 30px;
  padding-bottom: 90px; }
  @media (max-width: 1000px) {
    .blog_title {
      padding-top: 0px; } }

.blog_controls {
  display: flex;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  padding-left: 120px;
  padding-top: 200px;
  color: #3A3D92; }

.next {
  margin-left: 10px; }

.prev {
  margin-right: 10px; }

.blog_vector {
  transform: translateY(-5px); }

.blog_right {
  padding-left: 15px;
  width: 100%; }

.slider_item {
  width: 330px;
  height: 489px;
  position: relative;
  background-color: #3A3D92; }

.slider_item img {
  width: 330px;
  height: 489px; }

.item_date {
  float: right;
  padding: 24px;
  color: #ffffff;
  z-index: 5646; }

.item_title {
  padding-left: 33px;
  padding-top: 89px;
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  width: 260px; }

.item_description {
  padding-left: 33px;
  padding-top: 48px;
  width: 259px;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px; }

.item_link {
  color: #ffffff;
  position: absolute;
  right: 30px;
  bottom: 16px; }

.blog_bottom {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center; }

@media (max-width: 430px) {
  .blog {
    flex-direction: column;
    padding: 0; }
  .blog_left {
    margin-top: 50px; }
  .blog_right {
    padding-left: 0;
    padding-bottom: 0px; }
  .blog_bottom {
    padding: 20px; }
  .blog_link {
    padding-left: 0px;
    padding-top: 0px; }
  .blog_title {
    width: 100%; }
  .item_description {
    font-size: 13px;
    padding: 0 30px;
    width: 100%; }
  .blog_controls {
    padding-top: 0;
    padding-left: 200px;
    transform: translateY(540px); }
  .slider_item {
    height: 336px; }
  .item_date {
    font-size: 12px;
    padding: 15px; }
  .item_title {
    font-size: 16px;
    padding: 50px 30px 20px 30px; } }
