.modal_name {
  display: none; }

.modal__block_fast_buy .modal_name {
  display: flex;
  width: 100%;
  text-align: center;
  margin: 10px 0; }

.field_error {
  margin: 5px 0;
  width: 100%;
  text-align: center; }

.order__block .validation_error {
  bottom: -18px;
  left: 10px; }

.profile_section .validation_error {
  bottom: -20px;
  left: 10px; }

.modal_loading__block {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: none; }
  .modal_loading__block img {
    width: 100%;
    height: 100%; }
  .modal_loading__block.active {
    transition: all .2s;
    display: block;
    z-index: 10000; }

.modal_accept__block, .modal_bad__block {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 6px;
  background: #fff;
  align-items: center;
  display: none;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  box-shadow: 10px 5px 5px rosybrown; }
  .modal_accept__block.active, .modal_bad__block.active {
    transition: all .2s;
    display: flex;
    z-index: 10000; }

.modal_accept_svg, .modal_bad_svg {
  width: 30px;
  height: 30px;
  margin-bottom: 10px; }
  .modal_accept_svg img, .modal_bad_svg img {
    width: 100%; }

.modal_accept_text, .modal_bad_text {
  width: 100%;
  text-align: center; }
