@charset "UTF-8";
body {
  max-width: 1440px;
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif; }

a {
  text-decoration: none; }

li {
  list-style-type: none; }

.delivery_hint_hidden .header {
  padding-top: 0;
  top: 0; }

.delivery_hint_hidden .delivery_hint__block {
  margin-top: -30px; }

.delivery_hint_hidden .nav-frame_mob {
  top: 60px; }

.header {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .header_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
  .header_hamburger {
    overflow: hidden;
    position: relative;
    width: 65px;
    height: 60px;
    background: #2F2483;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .header_line {
    transition: all .2s;
    position: absolute;
    width: 30px;
    height: 5px;
    border-radius: 18px;
    margin: 4px 0;
    cursor: pointer;
    background: #FFFFFF; }
    .header_line_1 {
      top: 16px;
      left: 15px; }
    .header_line_2 {
      width: 23px;
      top: 24px;
      left: 15px; }
    .header_line_3 {
      top: 32px;
      left: 15px; }
  .header.active .header_line_1 {
    top: 24px;
    left: 15px;
    transform: rotate(45deg); }
  .header.active .header_line_2 {
    left: -100px; }
  .header.active .header_line_3 {
    top: 24px;
    left: 15px;
    transform: rotate(-45deg); }
  .header.active .nav-frame_mob {
    opacity: 1;
    left: 0;
    z-index: 3; }

.header_left {
  display: flex;
  justify-content: space-between; }

.header_right {
  display: flex;
  flex-direction: row-reverse; }

@media (max-width: 1300px) {
  .navFrame {
    display: none; } }

.navFrame ul {
  margin-top: 30px;
  display: flex; }

.navFrame .icon {
  display: none; }

.nav-frame_mob {
  display: flex;
  opacity: 0;
  background: white;
  padding: 20px;
  width: 300px;
  height: 100vh;
  transition: 0.2s all;
  position: fixed;
  left: -110%;
  top: 60px;
  flex-direction: column;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25); }
  .nav-frame_mob a {
    color: #2F2483;
    font-size: 20px;
    margin-bottom: 30px;
    text-transform: uppercase; }

.navLink {
  margin-left: 18px;
  color: #333333;
  text-transform: uppercase; }

.top_search {
  display: flex; }

.ser-ico {
  margin-top: 20px;
  margin-right: 20px;
  width: 40px;
  height: 40px; }

.icon {
  background-color: #3A3D92; }

.icotel {
  background-color: #3A3D92; }

.searching {
  height: 20px;
  width: 150px;
  display: none; }

.searching :active {
  display: block; }

.topContacts {
  display: flex; }

.topContacts a.icotel {
  display: none; }

.location {
  padding: 30px;
  background-color: #F2F2F2;
  color: darkblue;
  font-weight: 600; }

.header_phone {
  padding: 30px;
  background-color: black;
  color: #ffffff;
  font-weight: 500; }

.header_phone::before {
  margin-right: 5px; }

.contactUs {
  padding: 29px 59px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  background-color: #3A3D92;
  color: #ffffff; }

.pages {
  display: flex; }
  .pages-left, .pages-right {
    width: 50%;
    height: 69px;
    position: relative;
    display: flex; }
    .pages-left img, .pages-right img {
      width: 100%;
      height: 100%; }
    .pages-left div, .pages-right div {
      margin-left: 30px; }

.pages-left img {
  position: absolute;
  z-index: -1;
  width: 100%; }

.pages-left_content {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  margin-left: 30px;
  display: flex;
  align-items: center; }
  .pages-left_content a {
    color: #000000;
    font-weight: 700; }
  .pages-left_content h1 {
    font-size: 15px; }
  .pages-left_content .arrow {
    color: #333333;
    font-weight: 400; }
    .pages-left_content .arrow::after {
      content: "→";
      padding: 0 10px;
      color: #C4C4C4; }

.pages-left_content span {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase; }

.pages-right img {
  position: absolute; }

.pages-right_descr {
  position: absolute;
  color: #ffffff;
  z-index: 3;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  margin-left: 90px;
  margin-top: 25px; }

.delivery_hint__block {
  max-width: 1440px;
  position: fixed;
  z-index: 999;
  transition: all .2s;
  margin-top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
  height: 30px; }
  .delivery_hint__block.hidden {
    margin-top: -30px; }

.delivery_hint_text {
  display: block;
  padding-left: 10px;
  color: white; }

@keyframes marquee {
  from {
    transform: translate(0, 0); }
  to {
    transform: translate(-100%, 0); } }
  @media (max-width: 1000px) {
    .delivery_hint_text {
      width: 90%;
      white-space: nowrap;
      overflow: hidden; }
      .delivery_hint_text span {
        display: inline-block;
        padding-left: 100%;
        animation: marquee 10s infinite linear; } }

.delivery_hint_close {
  position: absolute;
  right: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  cursor: pointer; }
  .delivery_hint_close svg {
    width: 100%;
    height: 100%; }
  .delivery_hint_close path {
    transition: all .2s;
    stroke: #BDBDBD; }
  .delivery_hint_close:hover path {
    stroke: #73CAC2; }

@media (max-width: 1300px) {
  .header {
    height: 60px;
    position: fixed;
    background: white;
    z-index: 15;
    width: 100%;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    top: 0px; }
    .header_hamburger {
      display: flex; }
  .logo img {
    width: 191px;
    height: 50px; }
  .top_logo {
    display: none; }
  .icon {
    width: 60px;
    height: 60px; }
  .icon img {
    padding: 15px; }
  .icotel img {
    padding: 15px; }
  .topContacts a {
    display: none; }
  .topContacts a.icotel {
    display: block; }
  .pages {
    margin-left: 0px;
    padding-top: 60px; } }

@media (max-width: 900px) {
  .pages {
    flex-direction: column; }
    .pages-left, .pages-right {
      width: 100%; } }

@media (max-width: 430px) {
  .icotel {
    height: 60px;
    width: 60px; }
  .navFrame ul {
    margin-top: 0; } }
